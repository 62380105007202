interface CardBack {
  summary: string,
  upright: string[],
  inverted: string[],
}

export interface FlashCard {
  suit: string,
  number: number,
  title: string,
  image: string,
  answer: CardBack
};

export interface FlashCardGroup {
  name: string,
  cards: FlashCard[]
}

export enum AppComponents {
  MENU,
  QUIZ,
  LOOKUP
}

export interface State {
  unclaimed: FlashCard[],
  boxes: FlashCard[][]
}


export function shuffle(cardPack: any[]) {
  let copy = cardPack.slice(0);
  let currentIndex = copy.length,  randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [copy[currentIndex], copy[randomIndex]] = [copy[randomIndex], copy[currentIndex]];
  }
  return copy;
}