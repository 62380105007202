import { useEffect, useState } from 'react';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import './lookup.css';
import { AppComponents, FlashCard, FlashCardGroup } from '../util/Deck';
import { useSwipeable } from 'react-swipeable';


interface LookupProps {
  cardPacks: FlashCardGroup[],
}
interface GroupSelectProps {
  groupList: FlashCardGroup[],
  activeGroup: FlashCardGroup
}
interface CardSelectProps {
  cardGroup: FlashCardGroup,
  activeCard: FlashCard | null
}
interface CardDisplayProps {
  card: FlashCard,
}

function cardToSymbol(card: FlashCard) {
  if (card.suit === "Major Arcana" || card.number <= 10) {
    return card.number;
  } else {
    if (card.number === 11) return "P";
    if (card.number === 12) return "K";
    if (card.number === 13) return "Q";
    if (card.number === 14) return "R";
  }
}

function Lookup({cardPacks}: LookupProps) {

  const navigate = useNavigate();
  let { topicId } = useParams();

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => navigateUp(),
    onSwipedUp: () => navigateDown(),
    onSwipedLeft: () => navigateSideways(1),
    onSwipedRight: () => navigateSideways(-1),
    ...{swipeDuration: 250},
  });

  useEffect(() => {
    function handleKeyDown(e:KeyboardEvent) {
      if (e.key === "ArrowLeft") {
        return navigateSideways(-1);
      }
      if (e.key === "ArrowRight") {
        return navigateSideways(1);
      }
      if (e.key === "ArrowUp") {
        navigateUp();
      }
      if (e.key === "ArrowDown") {
        navigateDown();
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  function navigateUp() {
    if (activeCard) {
      return setActiveCardIdx(-1);
    } else {
      return navigate(-1);
    }
  }

  function navigateDown() {
    if (!activeCard) {
      return setActiveCardIdx(0);
    }
  }

  function selectGroup(groupIdx: number) {
    setActiveCardIdx(-1);
    setActiveGroupIdx(groupIdx);
  }

  function selectCard(cardIdx: number) {
    setActiveCardIdx(cardIdx);
  }

  function navigateSideways(direction: number) {
    if (activeCardIdx === -1) {
      let newGroupIdx = activeGroupIdx + direction;
      if (newGroupIdx < cardPacks.length && newGroupIdx > -1) {
        setActiveGroupIdx(newGroupIdx);
      }
    } else {
      // navigate cards
      let newCardIdx = activeCardIdx + direction;
      if (newCardIdx < activeGroup.cards.length && newCardIdx > -1) {
        setActiveCardIdx(newCardIdx);
      }
    }
  }

  const [activeGroupIdx, setActiveGroupIdx] = useState(0)
  const [activeCardIdx, setActiveCardIdx] = useState(-1);

  const activeGroup = cardPacks[activeGroupIdx];
  const activeCard = activeCardIdx > -1 ? cardPacks[activeGroupIdx].cards[activeCardIdx] : null;



  function GroupSelect({groupList, activeGroup}: GroupSelectProps) {
    return (
      <div className="cardgroups">
        {groupList.map((cardGroup, index) => (
          <button className={activeGroup.name == cardGroup.name ? "active" : ""} key={cardGroup.name} onClick={() => selectGroup(index)}>
            <div className="image square">
              <img src={`cards/${cardGroup.cards[0].image}`} alt={cardGroup.name} />
            </div>
          </button>
        ))}
      </div>
    );
  }


  function CardSelect({cardGroup, activeCard}: CardSelectProps) {
    return (
      <div className={`cardlist ${activeCard ? 'collapsed' : 'expanded'}`}>
        {cardGroup.cards.map((card, index) => (
          <button className={activeCard && activeCard.title == card.title ? "active" : ""} key={card.title} onClick={() => selectCard(index)}>
            <div className="image">
              <img src={`cards/${card.image}`} alt={card.title} />
            </div>
            <div className="symbol">
              {cardToSymbol(card)}
            </div>
          </button>
        ))}
      </div>
    );
  }


  function CardDisplay({card}: CardDisplayProps) {
    return (
      <div className="cardinfo">
        <h1>{card.title}</h1>
        <div className="cardcols">
          <div className="left">
            <img src={`cards/${card.image}`} alt={card.title}/>
          </div>
          <div className="right">
            <h2>{card.answer.summary}</h2>
            <h3>Upright:</h3>
            <ul>
              {card.answer.upright.map((item) => (
                <li key={item}>
                  {item}
                </li>
              ))}
            </ul>
            <h3>Inverted:</h3>
            <ul>
              {card.answer.inverted.map((item) => (
                <li key={item}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }



  return (
    <div className="lookup" {...swipeHandlers}>
      <GroupSelect groupList={cardPacks} activeGroup={activeGroup} />
      <CardSelect cardGroup={activeGroup} activeCard={activeCard} />
      { activeCard !== null && <CardDisplay card={activeCard} /> }
    </div>
  );
}

export default Lookup;
