import { useEffect, useState } from 'react';
import {
  Navigate,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { FlashCard, State } from '../util/Deck';
import './flash.css';
import { useSwipeable } from 'react-swipeable';


interface FlashProps {
  state: State,
  recordFunction: (cardToMove: FlashCard, from: number, success: number) => void
}
interface CardProps {
  card: FlashCard,
}

function Flash({state, recordFunction}: FlashProps) {

  const navigate = useNavigate();

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => recordResult(false),
    onSwipedUp: () => recordResult(true),
    onSwipedLeft: () => cycleCard(1),
    onSwipedRight: () => cycleCard(-1),
    ...{swipeDuration: 250},
  });

  useEffect(() => {

    function handleKeyDown(e:KeyboardEvent) {
      if (e.key === "ArrowUp") {
        return recordResult(true);
      }
      if (e.key === "ArrowDown") {
        return recordResult(false);
      }
      if (e.key === " ") {
        return flip();
      }
      if (e.key === "ArrowRight") {
        return cycleCard(1);
      }
      if (e.key === "ArrowLeft") {
        return cycleCard(-1);
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });


  function flip() {
    document.getElementsByClassName('card')[0].classList.toggle("flipped");
  }

  function cycleCard(direction: number) {
    let newCardIdx = currentCardIdx + direction;
    if (newCardIdx < 0 || newCardIdx >= remainingCards.length) return;
    setCurrentCardIdx(newCardIdx);
  }

  function recordResult(success: boolean) {
    // flash success
    let flasher = document.getElementsByClassName('flasher')[0];
    flasher.classList.add(success ? 'green' : 'red');
    setTimeout(() => {flasher.classList.remove('green'); flasher.classList.remove('red')}, 100);
    
    // record the result
    recordFunction(currentCard, boxIdx, success ? 1 : -1);

    // remove the card from the pack
    let newRemainingCards = remainingCards.slice();
    newRemainingCards.splice(currentCardIdx, 1);
    if (newRemainingCards.length === 0) {
      alert("You did them all");
      navigate(-1);
    } else {
      if (currentCardIdx === newRemainingCards.length) {
        setCurrentCardIdx(currentCardIdx - 1);
      }
      setCurrentRemainingCards(newRemainingCards);
    }
  }

  let {box} = useParams();
  let boxIdx = parseInt(box ? box : "0", 10);
  const [remainingCards, setCurrentRemainingCards] = useState(state.boxes[boxIdx] !== undefined ? state.boxes[boxIdx] : state.boxes[0]);
  const [currentCardIdx, setCurrentCardIdx] = useState(0);
  let currentCard = remainingCards[currentCardIdx];
    
  function Card({card}: CardProps) {
    return (
      <div className="card" onClick={flip} {...swipeHandlers}>
        <div className="flip-card">
          <div className="front" title={card.title} style={{backgroundImage: `url('/cards/${card.image}')`}}>
          </div>
          <div className="back">
            <div className="data">
              <h1>{card.title}</h1>
              <h2>{card.answer.summary}</h2>
              <h3>Upright:</h3>
              <ul>
                {card.answer.upright.map((item) => (
                  <li key={item}>
                    {item}
                  </li>
                ))}
              </ul>
              <h3>Inverted:</h3>
              <ul>
                {card.answer.inverted.map((item) => (
                  <li key={item}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function Controls() {
    return (
      <div className="controls">
        <button onClick={() => cycleCard(-1)}>
          Previous
          <br />
          (←)
        </button>
        <button onClick={() => recordResult(false)}>
          No
          <br />
          (↓)
        </button>
        <button onClick={() => recordResult(true)}>
          Yes
          <br />
          (↑)
        </button>
        <button onClick={() => cycleCard(1)}>
          Next
          <br />
          (→)
        </button>
      </div>
    );
  }

  if (!currentCard) {
    alert("This box is empty");
    return <Navigate to="/" />
  }
  return (
    <div className="flash">
      <div className="flasher"></div>
      <Card card={ currentCard } />
      <Controls />
    </div>
  );
}

export default Flash;
