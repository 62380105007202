import { useEffect, useState } from 'react';
import { Link, Form } from "react-router-dom";
import './menu.css';
import { AppComponents, FlashCardGroup, State } from '../util/Deck';


interface MenuProps {
  cardPacks: FlashCardGroup[],
  state: State,
  addCards: (arg0: number) => void,
  clearUnknowns: () => void,
  shuffleCards: () => void,
  reset: () => void,
}
interface MenuBarProps {
  showComponent: (arg0: AppComponents) => void,
}

function Menu({cardPacks, state, addCards, clearUnknowns, shuffleCards, reset}: MenuProps) {

  return (
    <div className="menu">

      <div className="buttonGroup">
        <h1>Card Management</h1>
        <button onClick={() => addCards(10)}>
          Add 10 cards<br />
          ({state.unclaimed.length})
        </button>
        <button onClick={clearUnknowns}>
          Reset New<br />
          ({state.boxes[0].length})
        </button>
        <button onClick={shuffleCards}>
          Shuffle<br />
          Packs
        </button>
      </div>

      <hr />

      <div className="buttonGroup">
        <h1>Quiz</h1>
        <Link to={`/flash/0`}>
          <button>
            New<br />
            ({state.boxes[0].length})
          </button>
        </Link>
        <Link to={`/flash/1`}>
          <button>
            Learning<br />
            ({state.boxes[1].length})
          </button>
        </Link>
        <Link to={`/flash/2`}>
          <button>
            Known<br />
            ({state.boxes[2].length})
          </button>
        </Link>
        <Link to={`/flash/3`}>
          <button>
            Review<br />
            ({state.boxes[3].length})
          </button>
        </Link>
      </div>

      <hr />
      <div className="buttonGroup">
        <h1>Lookup</h1>
        <Link to="/lookup">
          <button className="big">See Deck</button>
        </Link>
      </div>


      <hr/>
      <ul>
        <li>In FlashCards, keyboard shortcuts are:
          <ul>
            <li>Up, Down, Left, Right: as pictured</li>
            <li>Space: flip card</li>
          </ul>
        </li>
        <li>In Lookup, keyboard shortcuts are:
          <ul>
            <li>up for "move back up the selections"</li>
            <li>left for previous card</li>
            <li>right for next card</li>
          </ul>
        </li>
      </ul>

      <div className="buttonGroup">
        <button className="danger" onClick={reset}>Reset State</button>
      </div>
    </div>
  );
}

function MenuBar({showComponent}: MenuBarProps) {
  return (
    <div className="menubar">
      <button onClick={()=>{showComponent(AppComponents.MENU)}}>
        Menu
      </button>
    </div>
  );
}

export default Menu;
export {
  MenuBar
};
