import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { FlashCard, FlashCardGroup, shuffle, State } from './util/Deck';
import './app.css';
import { useState } from 'react';
import Menu from './routes/menu';
import Flash from './routes/flash';
import Lookup from './routes/lookup';



let deck: FlashCardGroup = require('./cards.json');
// preload card images
deck.cards.forEach((card) => {
  new Image().src = 'cards/' + card.image;
});

// packs of flash cards
let cardPacks: FlashCardGroup[] = [
  deck,
  {name: "Major Arcana",   cards: deck.cards.filter(card => card.suit === "Major Arcana")},
  {name: "Pentacles",      cards: deck.cards.filter(card => card.suit === "Pentacles")},
  {name: "Wands",          cards: deck.cards.filter(card => card.suit === "Wands")},
  {name: "Cups",           cards: deck.cards.filter(card => card.suit === "Cups")},
  {name: "Swords",         cards: deck.cards.filter(card => card.suit === "Swords")},
  {name: "Court Cards",    cards: deck.cards.filter(card => card.suit !== "Major Arcana" && card.number > 10)},
];

let lookupPacks: FlashCardGroup[] = cardPacks.filter(
  pack => [
    "Major Arcana", "Pentacles", "Wands", "Cups", "Swords"
  ].includes(pack.name));




export default function App() {

  function loadState() {
    let stateString = window.localStorage.getItem("state");
    if (!stateString) {
      return generateNewState();
    }
    return processState(stateString);
  }
  
  function saveState(state: State) {
    //return;
    setFlashcardState(state);
    let rawState = {
      unclaimed: state.unclaimed.map(card => card.title),
      boxes: [
        state.boxes[0].map(card => card.title),
        state.boxes[1].map(card => card.title),
        state.boxes[2].map(card => card.title),
        state.boxes[3].map(card => card.title),
      ]
    }
    window.localStorage.setItem("state", JSON.stringify(rawState));
  }
  
  function processState(stateString: string) {
    let rawState = JSON.parse(stateString);
    let state = {
      unclaimed: deck.cards.filter(card => rawState['unclaimed'].includes(card.title)),
      boxes: [
        deck.cards.filter(card => rawState.boxes[0].includes(card.title)),
        deck.cards.filter(card => rawState.boxes[1].includes(card.title)),
        deck.cards.filter(card => rawState.boxes[2].includes(card.title)),
        deck.cards.filter(card => rawState.boxes[3].includes(card.title)),
      ]
    };
    return state;
  }
  
  function generateNewState() {
    return {
      unclaimed: deck.cards,
      boxes: [
        [],[],[],[]
      ]
    }
  }
  
  function addCards(numCards: number) {
    let newState = { ...flashcardState};
    newState.unclaimed = shuffle(newState.unclaimed);
    for (var idx = 0; idx < numCards; idx++) {
      let card = newState.unclaimed.shift();
      if (card) {
        newState.boxes[0].push(card);
      } else {
        break;
      }
    }
    setFlashcardState(newState);
    saveState(newState);
  }

  function clearUnknowns() {
    let newState = { ...flashcardState};
    newState.unclaimed = newState.unclaimed.concat(newState.boxes[0]);
    newState.boxes[0] = [];
    setFlashcardState(newState);
    saveState(newState);
  }

  function moveCard(cardToMove: FlashCard, from: number, success: number) {
    let to = from + success;
    if (!flashcardState.boxes[to]) return;
    
    let newState = { ...flashcardState};
    newState.boxes[from] = newState.boxes[from].filter(card => card !== cardToMove);
    newState.boxes[to].push(cardToMove);
    saveState(newState);
  }

  function shuffleCards() {
    let newState = {
      unclaimed: flashcardState.unclaimed,
      boxes: [
        shuffle(flashcardState.boxes[0]),
        shuffle(flashcardState.boxes[1]),
        shuffle(flashcardState.boxes[2]),
        shuffle(flashcardState.boxes[3]),
      ]
    }
    setFlashcardState(newState);
  }

  function reset() {
    let confirm = window.confirm("Are you sure you want to reset your state?");
    if (confirm) {
      saveState(generateNewState());
      setTimeout(function() {
        alert("State reset");
      }, 10);
    }
  }
    
  let [flashcardState, setFlashcardState] = useState(loadState());

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Menu cardPacks={lookupPacks} state={flashcardState} addCards={addCards} clearUnknowns={clearUnknowns} shuffleCards={shuffleCards} reset={reset} />,
      //errorElement: <ErrorPage />
      
    },
    {
      path: "/lookup",
      element: <Lookup cardPacks={lookupPacks} />
    },
    {
      path: "/flash/:box",
      element: <Flash state={flashcardState} recordFunction={moveCard}  />
    },
  ]);


  return (
    <RouterProvider router={router} />
  );
}